// plugins/axios.js
import axios from 'axios'
import axiosRetry from 'axios-retry'
import { v4 as uuidv4 } from 'uuid'

export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig()
  axios.defaults.baseURL = runtimeConfig.public.apiBaseUrl

  // Configure axios-retry
  axiosRetry(axios, {
    retries: 0, // Default to 4 retries
    retryDelay: (retryCount) => {
      return retryCount * 1000 // time interval between retries
    },
    retryCondition: (error) => {
      // retry on Network Error & 5xx status codes
      return (
        axiosRetry.isNetworkOrIdempotentRequestError(error) ||
        (error.response && error.response.status >= 500)
      )
    },
  })

  // Add request interceptor to set X-Request-ID
  axios.interceptors.request.use(
    (config) => {
      if (config['axios-retry'] && config['axios-retry'].retries > 0) {
        config.headers['X-Request-ID'] =
          config.headers['X-Request-ID'] || uuidv4()
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    },
  )

  // Make axios available globally
  nuxtApp.provide('axios', axios)
})
