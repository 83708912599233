export default defineNuxtPlugin((nuxtApp) => {
  const authStore = useAuthStore()

  nuxtApp.hook('app:mounted', () => {
    authStore.verifyAuth()
  })

  nuxtApp.hook('page:start', () => {
    authStore.verifyAuth()
  })
})
