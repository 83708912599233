import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin({
  name: 'sentry',
  parallel: true,
  async setup(nuxtApp) {
    const vueApp = nuxtApp.vueApp

    const config = useRuntimeConfig()

    Sentry.init({
      app: vueApp,
      dsn: config.public.SENTRY_DSN_PUBLIC ?? null,
      tracesSampleRate: config.public.SENTRY_TRACES_SAMPLE_RATE as number,
      debug: config.public.ENVIRONMENT === 'development',
    })
  },
})
