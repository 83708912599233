import { ref } from 'vue'
import { defineStore } from 'pinia'
import ApiService from '@/services/ApiService'
import JwtService from '@/services/JwtService'
import FirebaseService from '@/services/FirebaseService'
import { jwtDecode } from 'jwt-decode'
import { updateProfile, type Auth } from 'firebase/auth'

const firebaseService = new FirebaseService()

export type LoginCredentials = {
  email: string
  password: string
}

type TokenData = {
  token: string
}

export interface User {
  name: string
  surname: string
  address: string
  logoUrl: string
  email: string
  access_token: string
  token_type: string
  permissions?: string
  profileComplete: boolean
}

export const useAuthStore = defineStore('auth', () => {
  const errors = ref({})
  const user = ref<User>({} as User)
  const isAuthenticated = ref(false)

  // Initialize authentication state from local storage
  function initAuthState() {
    const token = JwtService.getToken()
    if (token) {
      setAuth({ token: token })
    }
  }

  if (import.meta.client) {
    initAuthState()
  }

  function setAuth(tokenData: TokenData) {
    isAuthenticated.value = true
    user.value = jwtDecode(tokenData.token) // Assumes jwtDecode works as expected
    JwtService.saveToken(tokenData.token)
  }

  function setError(error: any) {
    console.log(error)
    errors.value = { ...error }
  }

  function purgeAuth() {
    isAuthenticated.value = false
    user.value = {} as User
    errors.value = []
    JwtService.destroyToken()
  }

  function logout() {
    purgeAuth()
  }

  async function signInWithGoogle(firebaseAuth: Auth) {
    console.log('Signing in with Google...')
    try {
      console.log('Signing in with Google...')
      const userCredential =
        await firebaseService.signInWithGoogle(firebaseAuth)
      console.log('User credential:', userCredential)
      if (userCredential.user) {
        const idToken = await userCredential.user.getIdToken()
        console.log('ID token:', idToken)
        return idToken // Return the ID token to chain .then() outside
      } else {
        throw new Error('No user credential returned from Google sign-in')
      }
    } catch (error) {
      console.error('Error signing in with Google:', error)
      throw error // Rethrow the error to handle it in .catch() outside
    }
  }

  async function signUpWithEmailAndPassword(
    firebaseAuth: Auth,
    email: string,
    password: string,
  ) {
    return firebaseService.signUpWithEmailAndPassword(
      firebaseAuth,
      email,
      password,
    )
  }

  async function signInWithEmailAndPassword(
    firebaseAuth: Auth,
    email: string,
    password: string,
  ) {
    return firebaseService.signInWithEmailAndPassword(
      firebaseAuth,
      email,
      password,
    )
  }

  async function listenForSSOSuccess(firebaseAuth: Auth) {
    return new Promise(async (resolve, reject) => {
      try {
        const userResult =
          await firebaseService.listenForRedirectResult(firebaseAuth)
        if (userResult) {
          // Handle user data and authentication logic here
          resolve(userResult) // Resolve the promise with user data
        } else {
          resolve(null) // Resolve with null if no user is found
        }
      } catch (error) {
        reject(error) // Reject the promise if there's an error
      }
    })
    // firebaseService.onAuthStateChanged(firebaseAuth)
  }

  function login(idToken: string, accountType: string) {
    return ApiService.post(`/authentication/login/firebase/`, {
      idToken: idToken,
      accountType: accountType,
    })
      .then(({ data }) => {
        setAuth(data)
      })
      .catch(({ response }) => {
        setError(response.data.errors)
      })
  }

  function forgotPassword(email: string) {
    return ApiService.post('forgot_password', email)
      .then(() => {
        setError({})
      })
      .catch(({ response }) => {
        setError(response.data.errors)
      })
  }

  function completeProfile(profileData: any) {
    return ApiService.put('/pet-owners/about', profileData, true)
      .then(({ data }) => {
        setAuth(data)
      })
      .catch(({ response }) => response.data.errors)
  }

  function completeVetProfile(profileData: any) {
    return ApiService.put('/veterinarians/about', profileData, true)
      .then(({ data }) => {
        setAuth(data)
      })
      .catch(({ response }) => response.data.errors)
  }

  function verifyAuth() {
    const token = JwtService.getToken()
    if (token) {
      ApiService.setHeader() // Set the token in the API service header
      setAuth({ token: token }) // Set the auth state based on the token
    } else {
      purgeAuth() // If no token, set the auth state to logged out
    }
  }

  return {
    errors,
    user,
    isAuthenticated,
    completeProfile,
    completeVetProfile,
    login,
    logout,
    forgotPassword,
    verifyAuth,
    signInWithGoogle,
    signUpWithEmailAndPassword,
    signInWithEmailAndPassword,
    listenForSSOSuccess,
  }
})
