// services/ApiService.js
import axios from 'axios'
import type { AxiosResponse } from 'axios'
import JwtService from '@/services/JwtService'
import { v4 as uuidv4 } from 'uuid'

class ApiService {
  public static setHeader(): void {
    axios.defaults.headers.common['Authorization'] =
      `Bearer ${JwtService.getToken()}`
    axios.defaults.headers.common['Accept'] = 'application/json'
  }

  public static async query(
    resource: string,
    params: any,
  ): Promise<AxiosResponse> {
    try {
      return await axios.get(resource, { params })
    } catch (error) {
      this.handleErrors(error)
    }
  }

  public static async get(resource: string): Promise<AxiosResponse> {
    try {
      return await axios.get(resource)
    } catch (error) {
      this.handleErrors(error)
    }
  }

  public static async post(
    resource: string,
    params: any,
    isFormData: boolean = false,
    retries: number = 0,
  ): Promise<AxiosResponse> {
    let headers = {}
    if (isFormData) {
      headers = { 'Content-Type': 'multipart/form-data' }
    }
    try {
      return await axios.post(`${resource}`, params, {
        headers,
        'axios-retry': { retries },
      })
    } catch (error) {
      this.handleErrors(error)
    }
  }

  public static async update(
    resource: string,
    slug: string,
    params: any,
  ): Promise<AxiosResponse> {
    try {
      return await axios.put(`${resource}/${slug}`, params)
    } catch (error) {
      this.handleErrors(error)
    }
  }

  public static async put(
    resource: string,
    params: any,
    isFormData: boolean = false,
  ): Promise<AxiosResponse> {
    let headers = {}
    if (isFormData) {
      headers = { 'Content-Type': 'multipart/form-data' }
    }
    try {
      return await axios.put(`${resource}`, params, { headers })
    } catch (error) {
      this.handleErrors(error)
    }
  }

  public static async delete(resource: string): Promise<AxiosResponse> {
    try {
      return await axios.delete(resource)
    } catch (error) {
      this.handleErrors(error)
    }
  }

  private static handleErrors(error: any): void {
    if (error.response) {
      console.error(
        `API Error: ${error.response.status} - ${error.response.statusText}`,
      )
    } else if (error.request) {
      if (error.code === 'ERR_NETWORK') {
        JwtService.destroyToken()
        throw new Error('Authentication token is invalid or has expired.')
      }
    } else {
      console.error(`API Error: ${error.message}`)
    }
    throw error
  }
}

export default ApiService
