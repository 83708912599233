import { default as aboutcggDDldG7iMeta } from "/vercel/path0/pages/about.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as indexvOPDQnfTU6Meta } from "/vercel/path0/pages/meetings/[id]/index.vue?macro=true";
import { default as notesWAEqqOg22GMeta } from "/vercel/path0/pages/meetings/[id]/notes.vue?macro=true";
import { default as indexWevLMfGMnZMeta } from "/vercel/path0/pages/meetings/index.vue?macro=true";
import { default as cancelrd10GAry1fMeta } from "/vercel/path0/pages/payment/cancel.vue?macro=true";
import { default as successyZ3B4PpEueMeta } from "/vercel/path0/pages/payment/success.vue?macro=true";
import { default as detailsdPUK1pxaI2Meta } from "/vercel/path0/pages/pets/[id]/details.vue?macro=true";
import { default as addb6L1E8lWHfMeta } from "/vercel/path0/pages/pets/add.vue?macro=true";
import { default as indexhCRoEJkY0QMeta } from "/vercel/path0/pages/pets/index.vue?macro=true";
import { default as privacy_45policyQl0qlywOJTMeta } from "/vercel/path0/pages/privacy-policy.vue?macro=true";
import { default as indexSsrwCXeuTQMeta } from "/vercel/path0/pages/profile/index.vue?macro=true";
import { default as signupY93ILdpGpbMeta } from "/vercel/path0/pages/signup.vue?macro=true";
import { default as toseBlBWjm25XMeta } from "/vercel/path0/pages/tos.vue?macro=true";
import { default as vet_45signup2EfF45qatvMeta } from "/vercel/path0/pages/vet-signup.vue?macro=true";
import { default as indexqn4h114uTTMeta } from "/vercel/path0/pages/vet/meetings/[id]/index.vue?macro=true";
import { default as notesfLciMZZiSVMeta } from "/vercel/path0/pages/vet/meetings/[id]/notes.vue?macro=true";
import { default as indexNoavtLUQHPMeta } from "/vercel/path0/pages/vet/meetings/index.vue?macro=true";
import { default as editjDT0EzlM9UMeta } from "/vercel/path0/pages/vet/profile/edit.vue?macro=true";
import { default as indexejfxmVKakwMeta } from "/vercel/path0/pages/vet/profile/index.vue?macro=true";
import { default as welcomeyxITOtNKSPMeta } from "/vercel/path0/pages/vet/welcome.vue?macro=true";
import { default as book_45meetinggwitFEf2vsMeta } from "/vercel/path0/pages/veterinarians/[id]/book-meeting.vue?macro=true";
import { default as index4OQHrGiEqRMeta } from "/vercel/path0/pages/veterinarians/index.vue?macro=true";
import { default as welcomelxppa1y2l8Meta } from "/vercel/path0/pages/welcome.vue?macro=true";
export default [
  {
    name: aboutcggDDldG7iMeta?.name ?? "about",
    path: aboutcggDDldG7iMeta?.path ?? "/about",
    meta: aboutcggDDldG7iMeta || {},
    alias: aboutcggDDldG7iMeta?.alias || [],
    redirect: aboutcggDDldG7iMeta?.redirect,
    component: () => import("/vercel/path0/pages/about.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: logint0AWlhQgM0Meta?.name ?? "login",
    path: logint0AWlhQgM0Meta?.path ?? "/login",
    meta: logint0AWlhQgM0Meta || {},
    alias: logint0AWlhQgM0Meta?.alias || [],
    redirect: logint0AWlhQgM0Meta?.redirect,
    component: () => import("/vercel/path0/pages/login.vue").then(m => m.default || m)
  },
  {
    name: indexvOPDQnfTU6Meta?.name ?? "meetings-id",
    path: indexvOPDQnfTU6Meta?.path ?? "/meetings/:id()",
    meta: indexvOPDQnfTU6Meta || {},
    alias: indexvOPDQnfTU6Meta?.alias || [],
    redirect: indexvOPDQnfTU6Meta?.redirect,
    component: () => import("/vercel/path0/pages/meetings/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: notesWAEqqOg22GMeta?.name ?? "meetings-id-notes",
    path: notesWAEqqOg22GMeta?.path ?? "/meetings/:id()/notes",
    meta: notesWAEqqOg22GMeta || {},
    alias: notesWAEqqOg22GMeta?.alias || [],
    redirect: notesWAEqqOg22GMeta?.redirect,
    component: () => import("/vercel/path0/pages/meetings/[id]/notes.vue").then(m => m.default || m)
  },
  {
    name: indexWevLMfGMnZMeta?.name ?? "meetings",
    path: indexWevLMfGMnZMeta?.path ?? "/meetings",
    meta: indexWevLMfGMnZMeta || {},
    alias: indexWevLMfGMnZMeta?.alias || [],
    redirect: indexWevLMfGMnZMeta?.redirect,
    component: () => import("/vercel/path0/pages/meetings/index.vue").then(m => m.default || m)
  },
  {
    name: cancelrd10GAry1fMeta?.name ?? "payment-cancel",
    path: cancelrd10GAry1fMeta?.path ?? "/payment/cancel",
    meta: cancelrd10GAry1fMeta || {},
    alias: cancelrd10GAry1fMeta?.alias || [],
    redirect: cancelrd10GAry1fMeta?.redirect,
    component: () => import("/vercel/path0/pages/payment/cancel.vue").then(m => m.default || m)
  },
  {
    name: successyZ3B4PpEueMeta?.name ?? "payment-success",
    path: successyZ3B4PpEueMeta?.path ?? "/payment/success",
    meta: successyZ3B4PpEueMeta || {},
    alias: successyZ3B4PpEueMeta?.alias || [],
    redirect: successyZ3B4PpEueMeta?.redirect,
    component: () => import("/vercel/path0/pages/payment/success.vue").then(m => m.default || m)
  },
  {
    name: detailsdPUK1pxaI2Meta?.name ?? "pets-id-details",
    path: detailsdPUK1pxaI2Meta?.path ?? "/pets/:id()/details",
    meta: detailsdPUK1pxaI2Meta || {},
    alias: detailsdPUK1pxaI2Meta?.alias || [],
    redirect: detailsdPUK1pxaI2Meta?.redirect,
    component: () => import("/vercel/path0/pages/pets/[id]/details.vue").then(m => m.default || m)
  },
  {
    name: addb6L1E8lWHfMeta?.name ?? "pets-add",
    path: addb6L1E8lWHfMeta?.path ?? "/pets/add",
    meta: addb6L1E8lWHfMeta || {},
    alias: addb6L1E8lWHfMeta?.alias || [],
    redirect: addb6L1E8lWHfMeta?.redirect,
    component: () => import("/vercel/path0/pages/pets/add.vue").then(m => m.default || m)
  },
  {
    name: indexhCRoEJkY0QMeta?.name ?? "pets",
    path: indexhCRoEJkY0QMeta?.path ?? "/pets",
    meta: indexhCRoEJkY0QMeta || {},
    alias: indexhCRoEJkY0QMeta?.alias || [],
    redirect: indexhCRoEJkY0QMeta?.redirect,
    component: () => import("/vercel/path0/pages/pets/index.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyQl0qlywOJTMeta?.name ?? "privacy-policy",
    path: privacy_45policyQl0qlywOJTMeta?.path ?? "/privacy-policy",
    meta: privacy_45policyQl0qlywOJTMeta || {},
    alias: privacy_45policyQl0qlywOJTMeta?.alias || [],
    redirect: privacy_45policyQl0qlywOJTMeta?.redirect,
    component: () => import("/vercel/path0/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: indexSsrwCXeuTQMeta?.name ?? "profile",
    path: indexSsrwCXeuTQMeta?.path ?? "/profile",
    meta: indexSsrwCXeuTQMeta || {},
    alias: indexSsrwCXeuTQMeta?.alias || [],
    redirect: indexSsrwCXeuTQMeta?.redirect,
    component: () => import("/vercel/path0/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: signupY93ILdpGpbMeta?.name ?? "signup",
    path: signupY93ILdpGpbMeta?.path ?? "/signup",
    meta: signupY93ILdpGpbMeta || {},
    alias: signupY93ILdpGpbMeta?.alias || [],
    redirect: signupY93ILdpGpbMeta?.redirect,
    component: () => import("/vercel/path0/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: toseBlBWjm25XMeta?.name ?? "tos",
    path: toseBlBWjm25XMeta?.path ?? "/tos",
    meta: toseBlBWjm25XMeta || {},
    alias: toseBlBWjm25XMeta?.alias || [],
    redirect: toseBlBWjm25XMeta?.redirect,
    component: () => import("/vercel/path0/pages/tos.vue").then(m => m.default || m)
  },
  {
    name: vet_45signup2EfF45qatvMeta?.name ?? "vet-signup",
    path: vet_45signup2EfF45qatvMeta?.path ?? "/vet-signup",
    meta: vet_45signup2EfF45qatvMeta || {},
    alias: vet_45signup2EfF45qatvMeta?.alias || [],
    redirect: vet_45signup2EfF45qatvMeta?.redirect,
    component: () => import("/vercel/path0/pages/vet-signup.vue").then(m => m.default || m)
  },
  {
    name: indexqn4h114uTTMeta?.name ?? "vet-meetings-id",
    path: indexqn4h114uTTMeta?.path ?? "/vet/meetings/:id()",
    meta: indexqn4h114uTTMeta || {},
    alias: indexqn4h114uTTMeta?.alias || [],
    redirect: indexqn4h114uTTMeta?.redirect,
    component: () => import("/vercel/path0/pages/vet/meetings/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: notesfLciMZZiSVMeta?.name ?? "vet-meetings-id-notes",
    path: notesfLciMZZiSVMeta?.path ?? "/vet/meetings/:id()/notes",
    meta: notesfLciMZZiSVMeta || {},
    alias: notesfLciMZZiSVMeta?.alias || [],
    redirect: notesfLciMZZiSVMeta?.redirect,
    component: () => import("/vercel/path0/pages/vet/meetings/[id]/notes.vue").then(m => m.default || m)
  },
  {
    name: indexNoavtLUQHPMeta?.name ?? "vet-meetings",
    path: indexNoavtLUQHPMeta?.path ?? "/vet/meetings",
    meta: indexNoavtLUQHPMeta || {},
    alias: indexNoavtLUQHPMeta?.alias || [],
    redirect: indexNoavtLUQHPMeta?.redirect,
    component: () => import("/vercel/path0/pages/vet/meetings/index.vue").then(m => m.default || m)
  },
  {
    name: editjDT0EzlM9UMeta?.name ?? "vet-profile-edit",
    path: editjDT0EzlM9UMeta?.path ?? "/vet/profile/edit",
    meta: editjDT0EzlM9UMeta || {},
    alias: editjDT0EzlM9UMeta?.alias || [],
    redirect: editjDT0EzlM9UMeta?.redirect,
    component: () => import("/vercel/path0/pages/vet/profile/edit.vue").then(m => m.default || m)
  },
  {
    name: indexejfxmVKakwMeta?.name ?? "vet-profile",
    path: indexejfxmVKakwMeta?.path ?? "/vet/profile",
    meta: indexejfxmVKakwMeta || {},
    alias: indexejfxmVKakwMeta?.alias || [],
    redirect: indexejfxmVKakwMeta?.redirect,
    component: () => import("/vercel/path0/pages/vet/profile/index.vue").then(m => m.default || m)
  },
  {
    name: welcomeyxITOtNKSPMeta?.name ?? "vet-welcome",
    path: welcomeyxITOtNKSPMeta?.path ?? "/vet/welcome",
    meta: welcomeyxITOtNKSPMeta || {},
    alias: welcomeyxITOtNKSPMeta?.alias || [],
    redirect: welcomeyxITOtNKSPMeta?.redirect,
    component: () => import("/vercel/path0/pages/vet/welcome.vue").then(m => m.default || m)
  },
  {
    name: book_45meetinggwitFEf2vsMeta?.name ?? "veterinarians-id-book-meeting",
    path: book_45meetinggwitFEf2vsMeta?.path ?? "/veterinarians/:id()/book-meeting",
    meta: book_45meetinggwitFEf2vsMeta || {},
    alias: book_45meetinggwitFEf2vsMeta?.alias || [],
    redirect: book_45meetinggwitFEf2vsMeta?.redirect,
    component: () => import("/vercel/path0/pages/veterinarians/[id]/book-meeting.vue").then(m => m.default || m)
  },
  {
    name: index4OQHrGiEqRMeta?.name ?? "veterinarians",
    path: index4OQHrGiEqRMeta?.path ?? "/veterinarians",
    meta: index4OQHrGiEqRMeta || {},
    alias: index4OQHrGiEqRMeta?.alias || [],
    redirect: index4OQHrGiEqRMeta?.redirect,
    component: () => import("/vercel/path0/pages/veterinarians/index.vue").then(m => m.default || m)
  },
  {
    name: welcomelxppa1y2l8Meta?.name ?? "welcome",
    path: welcomelxppa1y2l8Meta?.path ?? "/welcome",
    meta: welcomelxppa1y2l8Meta || {},
    alias: welcomelxppa1y2l8Meta?.alias || [],
    redirect: welcomelxppa1y2l8Meta?.redirect,
    component: () => import("/vercel/path0/pages/welcome.vue").then(m => m.default || m)
  }
]