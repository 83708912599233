const ID_TOKEN_KEY = 'access_token'

/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
  if (import.meta.client) {
    // Checks if the process is client-side
    return window.localStorage.getItem(ID_TOKEN_KEY)
  }
  return null
}

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
  if (import.meta.client) {
    // Checks if the process is client-side
    window.localStorage.setItem(ID_TOKEN_KEY, token)
  }
}

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  if (import.meta.client) {
    // Checks if the process is client-side
    window.localStorage.removeItem(ID_TOKEN_KEY)
  }
}

export default { getToken, saveToken, destroyToken }
