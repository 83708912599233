import {
  GoogleAuthProvider,
  signInWithPopup,
  signInWithRedirect,
  getRedirectResult,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from 'firebase/auth'
import type { Auth } from 'firebase/auth'

class FirebaseService {
  isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    )
  }

  isChromeBrowser() {
    return (
      /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)
    )
  }

  async signInWithGooglePopup(firebaseAuth: Auth) {
    const provider = new GoogleAuthProvider()
    return signInWithPopup(firebaseAuth, provider)
  }

  async signInWithGoogleRedirect(firebaseAuth: Auth) {
    const provider = new GoogleAuthProvider()
    return signInWithRedirect(firebaseAuth, provider)
  }
  async signInWithGoogle(firebaseAuth: Auth) {
    return this.signInWithGooglePopup(firebaseAuth)
    // if (!this.isMobileDevice() && this.isChromeBrowser()) {
    //   return this.signInWithGoogleRedirect(firebaseAuth)
    // } else {
    //   return this.signInWithGooglePopup(firebaseAuth)
    // }
  }

  async signUpWithEmailAndPassword(
    firebaseAuth: Auth,
    email: string,
    password: string,
  ) {
    const { user } = await createUserWithEmailAndPassword(
      firebaseAuth,
      email,
      password,
    )
    return user
  }

  async signInWithEmailAndPassword(
    firebaseAuth: Auth,
    email: string,
    password: string,
  ) {
    const { user } = await signInWithEmailAndPassword(
      firebaseAuth,
      email,
      password,
    )
    return user
  }

  listenForRedirectResult(firebaseAuth: Auth) {
    return getRedirectResult(firebaseAuth)
      .then((result: any) => {
        if (result?.user) {
          return result.user
        }
      })
      .catch((error: any) => {
        console.error('Google Sign-In failed:', error)
      })
  }

  // onAuthStateChanged(this.firebaseAuth, (firebaseUser) => {
  //   if (firebaseUser) {
  //     // Extract necessary user details
  //     const userDetails = {
  //       email: firebaseUser.email,
  //       displayName: firebaseUser.displayName,
  //       photoUrl: firebaseUser.photoURL,
  //       uid: firebaseUser.uid,
  //     }

  //     // Retrieve the auth token, update user state, and submit to backend
  //     firebaseUser.getIdToken().then((token) => {
  //       userDetails.authToken = token
  //       user.value = userDetails

  //       // Call the function with userDetails and authToken
  //       submitUserDetailsToBackend(userDetails, token)

  //       step.value = 2 // Proceed to next step
  //     })
  //   } else {
  //     user.value = null
  //   }
  // })
}

export default FirebaseService
